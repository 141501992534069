<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation background cover-text ps-0"  style="margin-top: 50px">
          <h1>T0算法 FT-T0</h1>
          <p>基于客户持有的股票持仓，利用机器学习技术，短周期预测，全自动操作，抓取行情波动价差，增厚收益</p>
        </div>
      </div>
    </div>
    <div class="content p-auto" style="margin-top:50px">
      <!--section>
        <div class="container-lg px-4 py-5">
          <div class="row align-items-center g-5 py-5">
            <div class="col-lg-12">
              <h1 class="display-5 fw-bold lh-1 mb-3 te">T+0服务</h1>
              <p class="lead mt-5">
                基于客户持有的股票持仓配合自研算法，每天全自动操作，抓取行情波动价差。操作后，每日持仓数不变，持仓成本降低，流动资金增加。
              </p>
            </div>
          </div>
        </div>
      </section-->
      <!--section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5">
              <div class="col-md-3 ml-auto">
                <h2>算法特点</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-4">
                <div class="product_media">
                  <div class="product_icon mx-3">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">逻辑清晰</h3>
                    <p class="text-dark text-left">
                      以股票底仓作为基础，进行日内价差交易获利；采用程序化高频交易，持仓时间短，严格止损，每日平仓
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-warning">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">合规风控</h3>
                    <p class="text-dark text-left">
                      盘前及时检查客户授权资金/授权股票情况；盘中个股交易敞口/执行进度监控；盘后运营介入，异常消息反馈
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-danger">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">简单实用</h3>
                    <p class="text-dark text-left">
                      自动盯盘，智能下单；极速委托，高效执行；降低持仓成本，获取超额收益
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section-->
      <!--section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>算法特点</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-teal">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">逻辑清晰</h3>
                    <p class="text-dark text-left">
                      以股票底仓作为基础，进行日内价差交易获利；采用程序化高频交易，持仓时间短，严格止损，每日平仓
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-primary">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">合规风控</h3>
                    <p class="text-dark text-left">
                      盘前及时检查客户授权资金/授权股票情况；盘中个股交易敞口/执行进度监控；盘后运营介入，异常消息反馈
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section-->
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>交易逻辑</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/交易底仓.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">交易底仓</h3>
                    <p class="text-dark text-left">
                      基于客户原有持仓，不推荐股票
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/日内平仓.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">日内平仓</h3>
                    <p class="text-dark text-left">
                      闭市后仓位与开盘前一致，日终敞口归零
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/自动交易.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">自动交易</h3>
                    <p class="text-dark text-left">
                      程序化智能交易，长期自动运行
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/灵活买卖.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">灵活买卖</h3>
                    <p class="text-dark text-left">
                      双向交易，可以先买后卖，也可先卖后买
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>交易特征</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/波动特性.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">波动特性</h3>
                    <p class="text-dark text-left">
                      高成交高波动环境下收益显著，低成交低波动环境下表现不佳
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/行情影响.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">行情影响</h3>
                    <p class="text-dark text-left">
                      短期之内急涨急跌，尤其在某一特定时间内方向比较明确，窄幅震荡行情表现相对较差
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/预留资金.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">预留资金</h3>
                    <p class="text-dark text-left">
                      策略运行需做好资金与持仓的分配，资金/策略市值约10%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>算法优势</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/信号预测准.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">信号预测准</h3>
                    <p class="text-dark text-left">
                      因子模型可实现多频段预测，针对个股特征进行精细化学习和处理，信号预测更准确
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/鲁棒性强.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">鲁棒性强</h3>
                    <p class="text-dark text-left">
                      策略储备丰富，适配不同行情；自动辨识客户的母单持仓情况，提供匹配子策略
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/日内回撤小.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">日内回撤小</h3>
                    <p class="text-dark text-left">
                      平均持仓时间短，敞口小，无需担心隔夜仓风险
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>算法风控</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/事前.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">事前</h3>
                    <p class="text-dark text-left">
                      事前风控模块进行算法巡检与仓位扫描，对比近日持仓价格，与客户及时沟通
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/盘中.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">盘中</h3>
                    <p class="text-dark text-left">
                      盘中严格执行风控要求，对个股交易敞口和执行情况进行实时监控，以保证用户持仓量正常
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/盘后.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">盘后</h3>
                    <p class="text-dark text-left">
                      盘后运营介入，异常信息反馈等多维度算法监控，全面预警，紧急处理，保障算法稳定运行
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-3 ml-auto">
                <h2>客户场景</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/高净值客户.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">高净值客户</h3>
                    <p class="text-dark text-left">
                      如上市公司股东、价值投资者等，持仓时间久，利用非凸 T0 算法获取收益
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/股票多头策略.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">股票多头策略</h3>
                    <p class="text-dark text-left">
                      交易频率低，利用 T0 算法盘活底仓，增厚收益
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/T0算法/量化阿尔法.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">量化阿尔法、指增策略</h3>
                    <p class="text-dark text-left">
                      利用非凸 T0 算法覆盖广、全自动的优势，捕捉股票日内波动，增厚产品收益
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "T0",
};
</script>

<style scoped>
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/products/T0算法.png");
  background-position-y: 20px;
  position: relative;
  top: 50px;
}
.cover-text {
  color: unset;
  text-shadow: none;
}
</style>
